// Vitrine.tsx

import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./Landing/Landing";
import WhatsNew from "./WhatsNew/WhatsNew";
import Blog from "./Blog/Blog";

import Nav from "./Nav";
import Page from "../Utilities/Page";

import data from "../../data.json";
import { LangData } from "../Utilities/Props";

import "../../styles/Vitrine/Vitrine.css";


export default function Vitrine() {
    return (
        <Page title="Vitrine">
            <Nav data={data['navBar'] as Record<string, LangData>}/>

            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/whatsNew" element={<WhatsNew/>}/>
                <Route path="/blog/*" element={<Blog/>}/>
            </Routes>
        </Page>
    )
}
