// Blog.tsx

import React from "react";
import { Route, Routes } from "react-router-dom";
import List from "./List";
import Article from "./Article";


export default function Blog() {
    return (
        <Routes>
            <Route path="/" element={<List/>}/>
            <Route path="/:link" element={<Article/>}/>
        </Routes>
    )
}
