// Forms.tsx

import React, { useEffect } from "react";

import Tab from "../../Utilities/Tab";

import { updateForms } from "../../../helpers/forms";

import FormSummary from "./FormSummary";
import Button from "../../Utilities/Button";

import "../../../styles/Dashboard/Forms.css";
import { useFormsContext } from "../../../contexts/FormsContext";
import { useNavigate } from "react-router-dom";


export default function Forms() {
    const { forms, setForms } = useFormsContext();
    const navigate = useNavigate();



    useEffect(() => {
        updateForms(navigate, setForms);
    }, []);


    return (
        <Tab title="Forms">
            <div className="widget container">
                <h1>Forms</h1>

                <Button title="Refresh" onClick={() => updateForms(navigate, setForms)}/>

                {forms.map((form, index) => (
                    <FormSummary key={index} form={form} callBack={() => updateForms(navigate, setForms)}/>
                ))}

            </div>
        </Tab>
    );
}
