// blog.tsx

import axios from 'axios';

import { getApiConfig } from './utils';
import { ArticleType } from '../types/Blog';


export async function getArticlesAbstracts() {
    const config = getApiConfig({ endpoint: 'getArticlesAbstracts' });

    const response = await axios.get(config.backendUrl);

    return response.data.articles as ArticleType[];
}


export async function getArticleByLink(link : string) {
    const config = getApiConfig({ endpoint: 'getArticleByLink' });

    const response = await axios.get(config.backendUrl, {
        params: {
            link
        }
    });

    return response.data as ArticleType;
}
