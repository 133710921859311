// readMarkDown.tsx

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';


interface ReadMarkdownProps {
    content?: string;
    text?: string;
}

export default function ReadMarkdown({ content, text }: ReadMarkdownProps) {
    const [ markdown, setMarkdown ] = useState("");

    useEffect(() => {
        if (content) {
            fetch(content)
                .then((res) => res.text())
                .then((text) => setMarkdown(text.replace(/\n/g, "\n\n")));
        } else if (text) {
            setMarkdown(text.replace(/\n/g, "\n\n"))
        }
    }, [ content, text ]);


    return (
        <ReactMarkdown>{markdown}</ReactMarkdown>
    );
}
