// HeaderForm.tsx

import React from "react";

import { FormType } from "../../../types/Form";

import { LangData } from "../../Utilities/Props";
import FormInput from "../../Utilities/FormInput";

import { useLangContext } from "../../../contexts/LangContext";

import Size from "./Size";

import '../../../styles/Dashboard/Form.css';


interface HeaderProps {
    form : FormType;
    setForm : (form : FormType) => void;
    data: Record<string, LangData>;
}

export default function Header({ form, setForm, data } : HeaderProps) {
    const lang = useLangContext().lang;

    return (
        <div className='widget header-container'>
            <h1>{data['title'][lang]}</h1>

            <div className="header">
                <FormInput
                    langData={data['title-input']}
                    value={form.title}
                    setValue={(value) => setForm({...form, title: value})}
                />
                <FormInput
                    langData={data['description-input']}
                    value={form.description}
                    setValue={(value) => setForm({...form, description: value})}
                />
            </div>

            <Size form={form} setForm={setForm}/>
        </div>
    );
}
