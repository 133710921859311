// Footer.tsx

import React from 'react';

import { useNavigate } from 'react-router-dom';

import '../../styles/Footer.css'
import '../../styles/size.css'
import '../../styles/color.css'

import { useLangContext } from '../../contexts/LangContext';

import allData from '../../data.json'

import { LangData } from '../Utilities/Props';

import Section from "./Section";
import Page from "./Page";

import CGU_FR from '../../documents/CGU_FR.md';
import ML_FR from '../../documents/ML_FR.md';
import CGU_EN from '../../documents/CGU_EN.md';
import ML_EN from '../../documents/ML_EN.md';


export default function Footer() {
    const { lang, setLang } = useLangContext();
    const navigate = useNavigate();

    const data = allData['Footer'];
    const langData = allData['languages'];

    return (
        <footer>
            <div id='sections'>
                <Section title={(data['more'] as LangData)[lang]}>
                    <Page name={(data['signIn'] as LangData)[lang]} onClick={() => navigate('/signIn')}/>
                    <Page name={(data['signUp'] as LangData)[lang]} onClick={() => navigate('/signUp')}/>
                    <Page name={(data['whatsNew'] as LangData)[lang]} onClick={() => navigate('/whatsNew')}/>
                    <Page name={(data['aboutUs'] as LangData)[lang]} onClick={() => window.location.href = 'https://www.all-enterprise.com'}/>
                    <Page name={(data['blog'] as LangData)[lang]} onClick={() => navigate('/blog')}/>
                </Section>
            </div>

            <div id='sections'>
                <Section title={(data['legal'] as LangData)[lang]}>
                    <Page content={lang === 'fr' ? CGU_FR : CGU_EN} name={(data['CGU'] as LangData)[lang]}/>
                    <Page content={lang === 'fr' ? ML_FR : ML_EN} name={(data['LegalNotice'] as LangData)[lang]}/>
                </Section>
                
                <Section title={(langData['title'] as LangData)[lang]}>
                    {Object.entries(langData.lang).map(([key, value]) => (
                        <Page key={key} name={(value as LangData)[lang]} onClick={() => setLang(key)}/>
                    ))}
                </Section>
            </div>

            <div id="copyright">
                {data.copyright[lang as keyof typeof data.copyright]}
            </div>
        </footer>
    );
}
