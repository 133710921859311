import axios from 'axios';

import { getApiConfig } from './utils';

import { AttendeeType } from '../types/Attendee';

export async function addAttendees(attendees : AttendeeType[], formId : number) {
    const config = getApiConfig({ endpoint: 'addAttendees' });

    await axios.post(config.backendUrl, {attendees, "formId" : formId}, {
        headers: {
            Authorization: config.token
        }
    });
}


export async function addAttendee(attendee : AttendeeType, formUUID : string) : Promise<string> {
    const config = getApiConfig({ endpoint: 'addAttendee' });

    const response = await axios.post(config.backendUrl,
        {
            "attendee" : attendee,
            "formUUID" : formUUID},
        {
            headers: {
                Authorization: config.token
            }
        }
    );

    return response.data.answerUUID;
}
