// Input.tsx

import React, { useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';

import Cross from "../../Utilities/Cross";
import { InputType } from "../../../types/Types";

import "../../../styles/Input.css";
import { useLangContext } from "../../../contexts/LangContext";
import { BlocProps, LangData } from "../../Utilities/Props";
import InformationLogo from "../../Utilities/Logos/InformationLogo";

import data from "../../../content/inputs.json";


interface RangeLabelProps {
    data: BlocProps;
}

function RangeLabel({ data } : RangeLabelProps) {
    const lang = useLangContext().lang;


    const [showInfo, setShowInfo] = useState(false);

    const handleMouseEnter = () => {
        setShowInfo(true);
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    const handleClick = () => {
        setShowInfo(!showInfo);
    };


    return (
        <div className="range-label-container">
            <span className="range-label">{data.title[lang]}</span>

            <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <InformationLogo/>
                {showInfo && (
                    <div className="info-popup">
                        <span>{data.title[lang]}</span>
                        {data.description && <p>{data.description[lang]}</p>}
                    </div>
                )}
            </div>
            
        </div>
    )
}


interface InputProps {
    input: InputType;
    setInput: (inputs: InputType) => void;
    removeInput?: () => void;
    style?: React.CSSProperties;
    className?: string;
}

export default function Input({ input, setInput, removeInput, style, className }: InputProps) {
    const lang = useLangContext().lang;

    function updateInputField(field: string, value: string | number) {
        setInput({...input, [field]: value});
    }

    return (
        <div className={className ? `input-container ${className}` : "input-container"} style={style}>
            {removeInput &&
                <div className="icon-container" onClick={removeInput}>
                    <Cross/>
                </div>
            }

            <div className="input-entries">
                <TextareaAutosize
                    className="title-input"
                    placeholder={(data['newInput'] as LangData)[lang]}
                    value={input.title}
                    onChange={e => updateInputField("title", e.target.value)}
                    minRows={1}
                />

                <div className="settings">
                    <RangeLabel data={data["complementary"] as BlocProps}/>

                    <input
                        type="range"
                        min="-100"
                        max="100"
                        step="1"
                        value={input.importance}
                        onChange={e => updateInputField("importance", parseInt(e.target.value))}
                    />

                    <RangeLabel data={data["similar"] as BlocProps}/>

                    <input 
                        className="importance-label"
                        value={input.importance}
                        onChange={e => updateInputField("importance", parseInt(e.target.value))}
                    />

                    <select
                        value={input.type}
                        onChange={e => updateInputField("type", e.target.value)}
                        className="custom-select"
                    >
                        <option value="text">{(data['text'] as LangData)[lang]}</option>
                        <option value="number">{(data['number'] as LangData)[lang]}</option>
                        <option value="date">{(data['date'] as LangData)[lang]}</option>
                        <option value="email">{(data['email'] as LangData)[lang]}</option>
                        <option value="tel">{(data['tel'] as LangData)[lang]}</option>
                        <option value="url">{(data['url'] as LangData)[lang]}</option>
                    </select>
                </div>

            </div>
        </div>
    )
}
