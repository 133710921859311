import React, { useEffect } from "react";

import { updateForms } from "../../../helpers/forms";

import '../../../styles/Dashboard/SideBar.css';
import { useFormsContext } from "../../../contexts/FormsContext";
import { useNavigate } from "react-router-dom";


export default function Recents() {
    const { forms, setForms } = useFormsContext();
    const navigate = useNavigate();

    useEffect(() => {
        updateForms(navigate, setForms);
    }, []);

    return (
        <>
        {forms.length > 0 &&
            <div className="recent-container widget">
                <h3>Recents</h3>

                {forms.slice(0, 3).map((form, index) => (
                    <div key={index} className="recent" onClick={() => (navigate(`/dashboard/results/${form.id}`))}>
                        {form.title}
                    </div>
                ))}

            </div>
        }
        </>
    )
}
