import React from "react";
import { BlocProps, LangData, LangDataList } from "./Props";

import { useLangContext } from "../../contexts/LangContext";

import "../../styles/Card.css";


interface imageType {
    url: string;
    alt: LangData;
    title: LangData;
}

interface CardType extends BlocProps {
    image?: imageType;
    date: LangData;
    tags?: LangDataList;
}

interface CardProps {
    data : CardType;
    onClick?: () => void;
}

export default function Card( { data, onClick } : CardProps) {
    const lang = useLangContext().lang;

    return (
        <div className="card widget" onClick={onClick}>
            {
                data.image &&
                <img src={data.image.url} alt={data.image.alt[lang]} title={data.image.title[lang]}/>
            }

            <div className="description">
                <h3>{data.title[lang]}</h3>
                {data.subtitle && <h4>{data.subtitle[lang]}</h4>}
                {data.description && <p>{data.description[lang]}</p>}
                <span className="date">{data.date[lang]}</span>

                {
                    data.tags &&
                    <div className="tags">
                        {
                            data.tags[lang].map((tag, index) => {
                                return <span key={index} className="tag">{tag}</span>
                            })
                        }
                    </div>
                }
            </div>
        </div>
    );
}
