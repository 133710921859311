import React, { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';

import '../../styles/Input.css';
import { LangData } from "./Props";
import { useLangContext } from "../../contexts/LangContext";


interface FormInputProps {
    label?: string;
    langData?: LangData;
    value: string;
    setValue: (value: string) => void;
    placeholder?: string;
    className?: string;
}

export default function FormInput({ value, setValue, label, placeholder, langData, className }: FormInputProps) {
    const lang = useLangContext().lang;
    const [ title, setTitle ] = useState<string>();

    useEffect(() => {
        if (langData) {
            setTitle(langData[lang]);
        } else if (label) {
            setTitle(label);
        }
    }, [ lang, langData, label ]);

    
    return (
        <div className={className ? `form-input ${className}` : "form-input"}>
            {title && <label>{title || label}</label>}

            <TextareaAutosize
                className="text-input"
                placeholder={placeholder || title || label}
                value={value}
                onChange={e => setValue(e.target.value)}
                minRows={1}
            />
        </div>
    )
}