import React, { useState } from "react";
import { BlocProps, LangData } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";

import "../../../styles/Vitrine/Landing.css";

type SectionType = {
    title : LangData;
    features : BlocProps[];
}

interface SectionProps {
    data : SectionType;
}

function Section({ data } : SectionProps) {
    const lang = useLangContext().lang;

    const [ selected, setSelected ] = useState(-1);

    function handleClick(index: number) {
        console.log("bonjour");
        if (selected == index) {
            setSelected(-1);
        } else {
            setSelected(index);
        }
    }

    return(
        <div className="section">
            <h3>{data.title[lang]}</h3>

            {data.features.map((feature, index) => {
                return(
                    <div key={index} className="feature" onClick={() => handleClick(index)}>
                        <h4>{feature.title[lang]}</h4>
                        {
                            selected == index && feature.description &&
                            <p>{feature.description[lang]}</p>
                        }
                    </div>
                )
            })}
        </div>
    )
}


interface PersonaeProps {
    data: Record<string, LangData | SectionType[]>;
}

export default function Personae({ data } : PersonaeProps) {
    const lang = useLangContext().lang;

    return(
        <div className="personae">
            <h2>{(data["title"] as LangData)[lang]}<span>{(data["personae"] as LangData)[lang]}</span> </h2>

            <p className="description">{(data['description'] as LangData)[lang]}</p>

            <div className="sections">
                {(data['useCases'] as SectionType[]).map((section, index) => {
                    return(
                        <Section key={index} data={section}/>
                    )
                })}
            </div>
        </div>
    )
}
