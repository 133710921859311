// Article.tsx

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import ReadMarkdown from "../../../helpers/readMarkDown";
import "../../../styles/Vitrine/Blog.css";

import { getFrontendUrl, useScroll } from "../../../helpers/utils";
import Footer from "../../Footer/Footer";
import { ArticleType } from "../../../types/Blog";
import { getArticleByLink } from "../../../helpers/blog";
import { useLangContext } from "../../../contexts/LangContext";
import { Helmet } from "react-helmet-async";


export default function Article() {
    const { link } = useParams<{ link : string }>();
    const [ article, setArticle ] = useState<ArticleType | null>(null);
    const lang = useLangContext().lang;

    useEffect(() => {
        if (!link) {
            return;
        }
        getArticleByLink(link).then((article) => {
            setArticle(article);
        });

    }, [ link ]);

    useScroll("article");

    return (
        <div id="article">
            <Helmet>
                <title>{article?.title[lang]}</title>
                <link rel="canonical" href={getFrontendUrl(`blog/${link}`)}/>
            </Helmet>

            <div className="content">
                {article && article.content && article.content[lang] && <ReadMarkdown text={article.content[lang]}/>}
            </div>

            <Footer/>
        </div>
    )
}
